.pair {
  display: flex;

  & > * {
    flex: 1 1 50%;
  }
}

.controller {
  margin-top: 48px;
}
