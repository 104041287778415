@import "/styles/variables.scss";
@import "/styles/font-bases.scss";

.container {
  background-color: $card-bg-biege;
  padding: 32px 32px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  position: relative;
  margin-right: 32px;

  &:last-of-type {
    margin-right: 0;
  }
}

.quote {
  position: absolute;
  top: 32px;
  left: 32px;
}

.avatarWrapper {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.content {
  @extend %app-typography-p22, %text-xl;

  margin-top: 20px;
  text-align: center;
}

.info {
  @extend %text-xl;

  margin-top: 16px;
}
