.arrow {
  background-color: white;
  padding: 12px 26px 10px;
  vertical-align: middle;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(white, 0.8);
  }

  &:disabled {
    opacity: 0.5;
  }
}
