@import "../../styles/font-bases.scss";
@import "../../styles/variables.scss";

.container {
  display: flex;
  cursor: pointer;
  position: relative;
  margin-bottom: 32px;

  &::before {
    content: "";
    width: 3px;
    height: Calc(100% + 24px * 2);
    position: absolute;
    left: 20px;
    background-color: white;
  }

  &:last-of-type {
    margin-bottom: 0;

    &::before {
      content: none;
    }
  }
}

.badge {
  @extend %app-subtitle;

  width: 40px;
  height: 40px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 5px rgba(black, 0.1);
  flex-shrink: 0;
  color: $app-green;
  transition: color 0.3s, background-color 0.3s ease;
  z-index: 1;
  margin-right: 24px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  opacity: 0.5;
  transition: opacity 0.3s ease;

  .title {
    @extend %app-subtitle;

    color: $app-almost-black;
    margin-bottom: 8px;
  }
}

.container:hover,
.container.active {
  .badge {
    color: white;
    background-color: $app-green;
  }

  .wrapper {
    opacity: 1;
  }
}
