.cardsContainer {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 1000px) {
  .cardsContainer {
    flex-direction: column;
    max-width: 548px;
    margin: auto;

    & > *:not(:first-of-type) {
      margin-top: 20px;
    }
  }
}
