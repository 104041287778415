@import "/styles/variables.scss";

.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
}

.dots {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}

.dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1px solid $app-almost-black;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:not(:first-of-type) {
    margin-left: 12px;
  }
}

.active {
  background-color: $app-almost-black;
}

.arrows > *:not(:first-of-type) {
  margin-left: 16px;
}

@media only screen and (max-width: 900px) {
  .arrows {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
