@import "/styles/variables.scss";

.container {
  margin-top: 32px;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 5px solid rgba($app-blue-grey, 0.3);
  border-radius: 50%;
  border-top-color: $app-blue-grey;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
