@use "sass:math";
@import "/styles/font-bases.scss";

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > * {
    flex: 1 1 0;
  }
}

.wrapper {
  margin-right: 64px;

  & > * {
    max-width: 548px;
  }
}

.contents {
  @extend %text-lg;

  margin-top: 32px;
}

.rightWrapper {
  position: relative;
  width: 100%;
}

.imageWrapper {
  position: relative;
  width: 100%;

  &::after {
    content: "";
    display: block;
    padding-top: 56.33% !important;
  }
}

.moreInformation {
  .title {
    @extend %app-subtitle;

    text-align: center;
    margin-bottom: 4px;

    p span {
      display: inline-block;
    }
  }

  .description {
    $arrow-size: 16px;
    margin-top: 16px + math.div($arrow-size, 2);
    padding: 24px 32px;
    background-color: white;
    position: relative;
    text-align: center;

    &::before {
      content: "";
      width: $arrow-size;
      height: $arrow-size;
      background-color: white;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, math.div($arrow-size, -2)) rotate(45deg);
    }
  }
}

@media only screen and (max-width: 1023px) {
  .container {
    flex-direction: column;
    align-items: center;

    h3 {
      text-align: center;
    }
  }

  .wrapper {
    margin-right: 0;

    & > * {
      max-width: 100%;
    }
  }

  .moreInformation {
    text-align: center;
  }

  .rightWrapper {
    margin-top: 40px;

    & > span {
      padding-top: 0 !important;
    }
  }
}
