.introIconTextGroup {
  margin-top: 130px;
}

.introLinks {
  margin-top: 60px;
}

.bostadCards {
  margin: 60px 0 50px 0;
}

.bostadLinks {
  margin-top: 48px - 32px;
}

.nyheterCards {
  margin-top: 60px;
}

@media only screen and (max-width: 900px) {
  .introIconTextGroup,
  .bostadCards {
    margin-top: 40px;
  }

  .columnReverse {
    flex-direction: column-reverse;

    & > div {
      &:nth-child(2) {
        margin-bottom: 40px;
        margin-top: 0;
      }
    }
  }
}
