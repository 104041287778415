@import "/styles/variables.scss";
@import "/styles/font-bases.scss";

.container {
  padding: 32px 32px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $card-bg-biege;
  border-radius: 4px;
  position: relative;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  margin-right: 32px;
  margin-bottom: 32px;

  // the icon should not shrink
  & > :first-child {
    flex-shrink: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
}

.white {
  background-color: white;
}

.label {
  @extend %app-subtitle;

  margin-top: 20px;
  color: $app-muted-blue;
  text-align: center;
}

.description {
  margin-top: 12px;
  text-align: center;
}

.spacer {
  margin-top: 24px;
  flex-grow: 1;
}

.readMoreWrapper {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;

  &.expanded {
    max-height: var(--app-height, 100vh);
  }

  .readMore {
    margin-top: 20px;
  }
}

.readMoreBtn {
  @extend %app-typography;

  padding: 6px 18px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  background: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  .readMoreMoreBtnIcon {
    transform: scale(0.8);
    margin-left: 10px;
  }

  &:hover {
    filter: invert(54%) sepia(23%) saturate(370%) hue-rotate(67deg) brightness(93%) contrast(92%);
  }
}

.banner {
  width: 140px;
  height: 140px;
  background-color: $app-muted-blue;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -70px;
  right: -18px;

  p {
    @extend %app-typography-p22, %text-lg, %text-sm-mobile;

    color: white;
    text-align: center;
  }

  p + p {
    margin-top: 0;
  }

  img {
    max-width: 90%;
    height: auto;
  }
}

@media only screen and (max-width: 900px) {
  .banner {
    width: 114px;
    height: 114px;
    top: -60px;
    right: -14px;
  }

  .container {
    min-width: 100%;
    min-height: 175px;
    margin-right: 0;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .banner {
    width: 84px;
    height: 84px;
    top: -20px;
    right: -14px;
  }
}
