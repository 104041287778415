@import "/styles/variables.scss";

.container {
  display: flex;
  margin-top: 80px;

  & > * {
    flex: 1 1 50vw;
  }
}

.imgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 412px;
  padding: 64px;
  background-color: $card-bg-biege;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  margin-right: 60px;

  & > span {
    -webkit-animation: fadein 2.5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2.5s; /* Firefox < 16 */
    -ms-animation: fadein 2.5s; /* Internet Explorer */
    -o-animation: fadein 2.5s; /* Opera < 12.1 */
    animation: fadein 2.5s;
  }
}

.stepsWrapper {
  display: flex;
  flex-direction: column;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 900px) {
  .container {
    flex-direction: column;
    margin: auto;
    margin-top: 40px;
  }

  .imgWrapper {
    padding: 30px;
    margin-right: 0;
    margin-bottom: 60px;
  }
}
