.nyheterCards {
  margin-top: 60px;
}

.silderController {
  margin-top: 40px;
}

.nyheterSliderWrapper {
  margin-top: 40px;
}
