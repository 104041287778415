.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (max-width: 900px) {
  .container {
    max-width: 548px;
    margin: auto;
  }
}
