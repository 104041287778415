@import "/styles/variables.scss";
@import "/styles/font-bases.scss";

.card {
  width: 33.33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $app-white;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  cursor: pointer;
  margin-right: 32px;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    opacity: 0.75;
  }

  .title {
    @extend %app-title, %app-title-mobile;

    color: $app-almost-black;
    text-align: center;
  }

  .cardImgWrapper {
    width: 100%;
    background-color: $card-bg-biege;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px;
  }

  .titleWrapper {
    width: 100%;
    text-align: center;
    padding: 32px;
  }
}

@media only screen and (max-width: 1000px) {
  .card {
    width: 100%;
  }
}
